// Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <span>&copy; {new Date().getFullYear()} MyCompany</span>
                <div className="footer-links">
                    <a href="/about">About</a>
                    <a href="/services">Services</a>
                    <a href="/contact">Contact</a>
                    <a href="/privacy">Privacy</a>
                </div>
                <div className="footer-social">
                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">FB</a>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">TW</a>
                    <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">IG</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
