import { Container, Spinner } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home"; // Assuming you have these components
import NotFound from './pages/NotFound/NotFound';
import  Header from "./shared/Header/Header";
import Footer from "./shared/Footer/Footer";

const App = () => {
  return (
    <><Header/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} /> {/* 404 route */}
      </Routes>
        <Footer/>
    </>
  );
};

export default App;
