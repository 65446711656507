import React from "react";
import { Card, Button } from "react-bootstrap";
import 'bootstrap-icons/font/bootstrap-icons.css';

const ConferenceCard = ({ conference }) => {
  if (!conference) {
    return <p>No conference data available.</p>;
  }

  return (
    <Card style={{ width: '100%', margin: '1rem', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
      <Card.Body>
        <Card.Title>{conference.Title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{conference.Acronym}</Card.Subtitle>
        <Card.Text>
          <strong>When:</strong> {conference.When} <br />
          <strong>Where:</strong> {conference.Where} <br />
          <strong>Submission Deadline:</strong> {conference.SubmissionDeadline || "N/A"} <br />
          <strong>Notification Due:</strong> {conference.NotificationDue || "N/A"} <br />
          <strong>Final Version Due:</strong> {conference.FinalVersionDue || "N/A"}
        </Card.Text>
        <Button 
          variant="primary" 
          href={conference.Link} 
          target="_blank" 
          rel="noopener noreferrer">
          <i className="bi bi-link"></i> Visit
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ConferenceCard;
