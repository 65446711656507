import React from "react";
import { Card, Button } from "react-bootstrap";
import 'bootstrap-icons/font/bootstrap-icons.css';
import ConferenceCard from './ConferenceCard'


const ConferenceCards = ({ data }) => {
  return (
    <div className="d-flex flex-wrap justify-content-center">
      {data.map((conference, index) => (
        <ConferenceCard key={index} conference={conference} />
      ))}
    </div>
  );
};

export default ConferenceCards;
