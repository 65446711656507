import React from "react";
import { Form } from "react-bootstrap";

const FilterDropdown = ({ locations, selectedLocation, handleFilter }) => {
  return (
    <Form.Group controlId="filter" className="mb-3">
      <Form.Label>Filter by Location:</Form.Label>
      <Form.Select value={selectedLocation} onChange={(e) => handleFilter(e.target.value)}>
        <option value="">All Locations</option>
        {locations.map((location, index) => (
          <option key={index} value={location}>
            {location}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default FilterDropdown;